import { Alert, Box, IconButton, Input, Typography } from '@mui/joy'
import MyCard from '../../MyCard'
import { useState } from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Collapse } from '@mui/material'
import CalculateIcon from '@mui/icons-material/Calculate'
import ClearIcon from '@mui/icons-material/Clear'

export default function Calculator() {
  const [expression, setExpression] = useState<string>('')
  const [result, setResult] = useState<number>(0)
  const [alertMessage, setAlertMessage] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState<boolean>(false)

  function alert(message: string) {
    setAlertMessage(message)
    setAlertOpen(true)
    setTimeout(() => {
      setAlertOpen(false)
    }, 2000)
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    let value = e.target.value
    value = value.replace(/,/, '.')
    value = value.replace(/[^.+\-*/()[\]{}0-9]/, '')
    setExpression(value)
  }

  function handleOnKeyEnterDown(e: React.KeyboardEvent) {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      calculate()
    }
    if (e.code === 'Escape') {
      setExpression('')
    }
  }

  function calculate() {
    if (/[.+\-*/()0-9]/g.test(expression)) {
      try {
        setResult(eval(expression))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        alert('Expressão inválida')
        console.log(error)
      }
    } else {
      alert('Forneça uma expressão matemática')
    }
  }

  return (
    <MyCard maxWidth='400px' width='70vw'>
      <Collapse in={alertOpen}>
        <Alert sx={{ marginBottom: '10px' }}>{alertMessage}</Alert>
      </Collapse>
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography level='h6' sx={{ color: 'white' }}>
          CALCULADORA
        </Typography>
        <Input
          variant='solid'
          readOnly={true}
          value={result}
          startDecorator={
            <IconButton variant='solid' onClick={calculate}>
              <CalculateIcon sx={{ color: 'whitesmoke' }} />
            </IconButton>
          }
          endDecorator={
            <IconButton>
              <ContentCopyIcon
                onClick={() => {
                  navigator.clipboard.writeText(String(result))
                  alert('Copiado!')
                }}
              />
            </IconButton>
          }
        />
        <Input
          value={expression}
          endDecorator={
            <IconButton onClick={() => setExpression('')}>
              <ClearIcon />
            </IconButton>
          }
          placeholder='EXPRESSÃO MATEMÁTICA'
          onChange={handleOnChange}
          onFocus={() => navigator.clipboard.writeText('')}
          onKeyDown={handleOnKeyEnterDown}
        />
      </Box>
    </MyCard>
  )
}
