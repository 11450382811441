import { Alert, Box, Button, Input, Textarea, Typography } from '@mui/joy'
import { QualificacaoType } from './AutoDocsIndex'
import MyCard from '../../MyCard'
import { doc, setDoc } from 'firebase/firestore'
import { db } from '../../../utils/firebase/firebaseStart'
import { useContext, useEffect, useState } from 'react'
import { ClienteContext } from '../../../context/ClientesContext'
import { Collapse } from '@mui/material'
import { v4 } from 'uuid'
import { cpfIsValid } from '../../../utils/cpfIsValid'

interface QualificacaoProps {
  qualificacao: QualificacaoType
  setQualificacao: React.Dispatch<React.SetStateAction<QualificacaoType>>
  resetQualificacao: () => void
}

export default function Qualificacao({
  qualificacao,
  setQualificacao,
  resetQualificacao,
}: QualificacaoProps) {
  const clienteContext = useContext(ClienteContext)
  const [alert, setAlert] = useState<{ message: string; open: boolean }>({
    message: '',
    open: false,
  })

  const [validCpf, setValidCpf] = useState(false)

  useEffect(() => {
    // CPF validation
    if (qualificacao.cpf.length >= 11) {
      if (cpfIsValid(qualificacao.cpf)) {
        setValidCpf(true)
      }
    } else setValidCpf(false)
  }, [qualificacao.cpf])

  useEffect(() => {
    setTimeout(() => {
      setAlert({ ...alert, open: false })
    }, 2000)
  }, [alert.open])

  function handleOnChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let value = e.target.value
    value = value.replace(/\.$/, '')

    switch (e.target.name) {
      case 'nome': {
        value = value.toLocaleUpperCase()
        break
      }
      case 'cpf': {
        e.target.maxLength = 14
        value = value.replace(/\D/g, '')
        value = value.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{2})/g,
          '$1.$2.$3-$4'
        )
        

        // check if 'cliente' exist by CPF
        const existingCliente = clienteContext.clientes.filter(
          (cliente) => cliente.cpf === value
        )[0]
        console.log(existingCliente)
        if (existingCliente) {
          // setQualificacao(() => ({ ...existingCliente }))
          qualificacao = { ...existingCliente }
        }
        break
      }
      case 'nascimento': {
        e.target.maxLength = 10
        value = value.replace(/\D/g, '')
        value = value.replace(/(\d{2})(\d{2})(\d{4})/g, '$1/$2/$3')
        break
      }
    }
    setQualificacao({ ...qualificacao, [e.target.name]: value })
  }

  async function saveCliente() {
    const cliente = {
      id: v4(),
      nome: qualificacao.nome,
      nacionalidade: qualificacao.nacionalidade,
      estadoCivil: qualificacao.estadoCivil,
      profissao: qualificacao.profissao,
      nascimento: qualificacao.nascimento,
      rg: qualificacao.rg,
      cpf: qualificacao.cpf,
      endereco: qualificacao.endereco,
    }
    const docRef = doc(db, 'advutils', 'clientes')

    const clienteCPFExists = clienteContext.clientes.filter(
      (cliente) => cliente.cpf === qualificacao.cpf
    ).length
      ? true
      : false
    const clientesCopy = [...clienteContext.clientes]

    // if cpf already exists, edit the 'cliente'
    if (clienteCPFExists) {
      const clienteIndex = clienteContext.clientes.indexOf(
        clienteContext.clientes.filter(
          (cliente) => cliente.cpf === qualificacao.cpf
        )[0]
      )
      clientesCopy[clienteIndex] = cliente
      clienteContext.setClientes(clientesCopy)
      setAlert({ message: `Cliente ${cliente.nome} atualisado`, open: true })
      await setDoc(docRef, { items: clientesCopy })

      // if cpf does not exist, then save this new 'cliente'
    } else {
      clientesCopy.push(cliente)
      try {
        clienteContext.setClientes(clientesCopy)
        await setDoc(docRef, { items: clientesCopy })
        setAlert({ message: `Cliente ${cliente.nome} salvo`, open: true })
      } catch (error: any) {
        console.log(error)
        setAlert({ message: error, open: true })
      }
    }
  }

  return (
    <MyCard>
      <Collapse in={alert.open}>
        <Alert sx={{ marginBottom: '10px' }}>{alert.message}</Alert>
      </Collapse>
      <Typography level='h2' sx={{ color: 'white', padding: '10px' }}>
        QUALIFICAÇÃO
      </Typography>
      <Box display='flex' flexDirection='column' gap='15px'>
        <Input
          startDecorator='CPF'
          value={qualificacao.cpf}
          onChange={handleOnChange}
          name='cpf'
          color={ validCpf ? 'primary' : qualificacao.cpf.length >= 11 ? 'danger' : 'info'}
        />
        <Input
          startDecorator='NOME'
          value={qualificacao.nome}
          onChange={handleOnChange}
          name='nome'
          disabled={validCpf ? false : true}
        />
        <Input
          startDecorator='NACIONALIDADE'
          value={qualificacao.nacionalidade}
          onChange={handleOnChange}
          name='nacionalidade'
          disabled={validCpf ? false : true}
        />
        <Input
          startDecorator='ESTADO CIVIL'
          value={qualificacao.estadoCivil}
          onChange={handleOnChange}
          name='estadoCivil'
          disabled={validCpf ? false : true}
        />
        <Input
          startDecorator='PROFISSÃO'
          value={qualificacao.profissao}
          onChange={handleOnChange}
          name='profissao'
          disabled={validCpf ? false : true}
        />
        <Input
          startDecorator='NASCIMENTO'
          value={qualificacao.nascimento}
          onChange={handleOnChange}
          name='nascimento'
          placeholder='dd/mm/aaaa'
          disabled={validCpf ? false : true}
        />
        <Input
          startDecorator='RG'
          value={qualificacao.rg}
          onChange={handleOnChange}
          name='rg'
          disabled={validCpf ? false : true}
        />
        <Textarea
          startDecorator='ENDEREÇO'
          minRows={2}
          value={qualificacao.endereco}
          onChange={handleOnChange}
          name='endereco'
          disabled={validCpf ? false : true}
        />
        <Box display='flex' gap='10px'>
          <Button onClick={resetQualificacao}>LIMPAR</Button>
          <Button onClick={saveCliente} disabled={validCpf ? false : true}>
            SALVAR
          </Button>
        </Box>
      </Box>
    </MyCard>
  )
}
