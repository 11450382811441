// https://www.cnj.jus.br/wp-content/uploads/2011/02/trfs.pdf

interface IOriginCodes {
  [keys: string]: string
}
export const tjrjCodes: IOriginCodes = {
  '0209': 'Regional da Barra da Tijuca',
  '0207': 'Regional da Ilha do Governador',
  '0210': 'Regional da Leopoldina',
  '0211': 'Regional da Pavuna',
  '0212': 'Regional da Região Oceânica',
  '0087': 'Regional de Alcântara',
  '0204': 'Regional de Bangu',
  '0205': 'Regional de Campo Grande',
  '0079': 'Regional de Itaipava',
  '0203': 'Regional de Jacarepaguá',
  '0202': 'Regional de Madureira',
  '0206': 'Regional de Santa Cruz',
  '0208': 'Regional do Méier',
  '0001': 'Comarca da Capital',
  '0255': 'Comarca da Capital - 1ª Vara da Infância, Juventude e Idoso',
  '0256': 'Comarca da Capital - 2ª Vara da Infância, Juventude e Idoso',
  '0251': 'Comarca da Capital - Copacabana',
  '0252': 'Comarca da Capital - Lagoa',
  '0253': 'Comarca da Capital - Tijuca',
  '0254': 'Comarca da Capital - Vila Isabel',
  '0003': 'Comarca de Angra dos Reis',
  '0052': 'Comarca de Araruama',
  '0005': 'Comarca de Arraial do Cabo',
  '0006': 'Comarca de Barra do Piraí',
  '0007': 'Comarca de Barra Mansa',
  '0008': 'Comarca de Belford Roxo',
  '0009': 'Comarca de Bom Jardim',
  '0010': 'Comarca de Bom Jesus de Itabapoana',
  '0078': 'Comarca de Búzios',
  '0011': 'Comarca de Cabo Frio',
  '0012': 'Comarca de Cachoeiras de Macacu',
  '0013': 'Comarca de Cambuci',
  '0014': 'Comarca de Campos dos Goytacazes',
  '0015': 'Comarca de Cantagalo',
  '0084': 'RComarca de Carapebus / Quissamã',
  '0016': 'Comarca de Carmo',
  '0017': 'Comarca de Casimiro de Abreu',
  '0018': 'Comarca de Conceição de Macabu',
  '0019': 'Comarca de Cordeiro',
  '0020': 'Comarca de Duas Barras',
  '0021': 'Comarca de Duque de Caxias',
  '0022': 'Comarca de Engenheiro Paulo de Frontin',
  '0073': 'Comarca de Guapimirim',
  '0069': 'Comarca de Iguaba Grande',
  '0023': 'Comarca de Itaboraí',
  '0024': 'Comarca de Itaguaí',
  '0080': 'Comarca de Italva',
  '0025': 'Comarca de Itaocara',
  '0026': 'Comarca de Itaperuna',
  '0081': 'Comarca de Itatiaia',
  '0083': 'Comarca de Japeri',
  '0027': 'Comarca de Laje do Muriaé',
  '0028': 'Comarca de Macaé',
  '0029': 'Comarca de Magé',
  '0075': 'Comarca de Magé - Regional de Inhomirim',
  '0030': 'Comarca de Mangaratiba',
  '0031': 'Comarca de Maricá',
  '0032': 'Comarca de Mendes',
  '0033': 'Comarca de Miguel Pereira',
  '0034': 'Comarca de Miracema',
  '0035': 'Comarca de Natividade',
  '0036': 'Comarca de Nilópolis',
  '0002': 'Comarca de Niterói',
  '0037': 'Comarca de Nova Friburgo',
  '0038': 'Comarca de Nova Iguaçu',
  '0039': 'Comarca de Paracambi',
  '0040': 'Comarca de Paraíba do Sul',
  '0041': 'Comarca de Paraty',
  '0072': 'Comarca de Paty do Alferes',
  '0042': 'Comarca de Petrópolis',
  '0082': 'Comarca de Pinheiral',
  '0043': 'Comarca de Piraí',
  '0044': 'Comarca de Porciúncula',
  '0071': 'Comarca de Porto Real - Quatis',
  '0067': 'Comarca de Queimados',
  '0045': 'Comarca de Resende',
  '0046': 'Comarca de Rio Bonito',
  '0047': 'Comarca de Rio Claro',
  '0048': 'Comarca de Rio das Flores',
  '0068': 'Comarca de Rio das Ostras',
  '0049': 'Comarca de Santa Maria Madalena',
  '0050': 'Comarca de Santo Antônio de Pádua',
  '0051': 'Comarca de São Fidelis',
  '0070': 'Comarca de São Francisco do Itabapoana',
  '0004': 'Comarca de São Gonçalo',
  '0053': 'Comarca de São João da Barra',
  '0054': 'Comarca de São João de Meriti',
  '0076': 'Comarca de São José do Vale do Rio Preto',
  '0055': 'Comarca de São Pedro da Aldeia',
  '0056': 'Comarca de São Sebastião do Alto',
  '0057': 'Comarca de Sapucaia',
  '0058': 'Comarca de Saquarema',
  '0077': 'Comarca de Seropédica',
  '0059': 'Comarca de Silva Jardim',
  '0060': 'Comarca de Sumidouro',
  '0061': 'Comarca de Teresópolis',
  '0062': 'Comarca de Trajano de Moraes',
  '0063': 'Comarca de Três Rios',
  '0064': 'Comarca de Valença',
  '0065': 'Comarca de Vassouras',
  '0066': 'Comarca de Volta Redonda',
  '0000': 'Processos de 2ª Instância',
  '9000': 'Processos das Turmas Recursais',
}

export const trfCodes: IOriginCodes = {
  // aqui estão os códigos somente do TRF2 Rio de Janeiro
  '5101': 'Rio de Janeiro - 1ª Turma Recursal',
  '5190': 'Rio de Janeiro - 1ª Turma Recursal',
  '5111': 'Angra dos Reis',
  '5119': 'Barra do Piraí',
  '5103': 'Campos dos Goytacazes',
  '5118': 'Duque de Caxias',
  '5107': 'Itaboraí',
  '5112': 'Itaperuna',
  '5116': 'Macaé',
  '5114': 'Magé',
  '5102': 'Niteroi',
  '5105': 'Nova Friburgo',
  '5120': 'Nova Iguaçu',
  '5106': 'Petrópolis',
  '5117': 'São Gonçalo',
  '5110': 'São João do Meriti',
  '5108': 'São Pedro da Aldeia',
  '5109': 'Resende',
  '5115': 'Teresópolis',
  '5113': 'Três Rios',
  '5104': 'Volta Redonda',
}

export const trtCodes: IOriginCodes = {
  // aqui estão os códigos somente do TRT1 Rio de Janeiro
  '0001': '1a Vara do Trabalho do Rio de Janeiro',
  '0002': '2a Vara do Trabalho do Rio de Janeiro',
  '0003': '3a Vara do Trabalho do Rio de Janeiro',
  '0004': '4a Vara do Trabalho do Rio de Janeiro',
  '0005': '5a Vara do Trabalho do Rio de Janeiro',
  '0006': '6a Vara do Trabalho do Rio de Janeiro',
  '0007': '7a Vara do Trabalho do Rio de Janeiro',
  '0008': '8a Vara do Trabalho do Rio de Janeiro',
  '0009': '9a Vara do Trabalho do Rio de Janeiro',
  '0010': '10a Vara do Trabalho do Rio de Janeiro',
  '0011': '11a Vara  do Trabalho do Rio de Janeiro',
  '0012': '12a Vara do Trabalho do Rio de Janeiro',
  '0013': '13a Vara do Trabalho do Rio de Janeiro',
  '0014': '14a Vara do Trabalho do Rio de Janeiro',
  '0015': '15a Vara do Trabalho do Rio de Janeiro',
  '0016': '16a Vara do Trabalho do Rio de Janeiro',
  '0017': '17a Vara do Trabalho do Rio de Janeiro',
  '0018': '18a Vara do Trabalho do Rio de Janeiro',
  '0019': '19a Vara do Trabalho do Rio de Janeiro',
  '0020': '20a Vara do Trabalho do Rio de Janeiro',
  '0021': '21a Vara do Trabalho do Rio de Janeiro',
  '0022': '22a Vara do Trabalho do Rio de Janeiro',
  '0023': '23a Vara do Trabalho do Rio de Janeiro',
  '0024': '24a Vara do Trabalho do Rio de Janeiro',
  '0025': '25a Vara do Trabalho do Rio de Janeiro',
  '0026': '26a Vara do Trabalho do Rio de Janeiro',
  '0027': '27a Vara do Trabalho do Rio de Janeiro',
  '0028': '28a Vara do Trabalho do Rio de Janeiro',
  '0029': '29a Vara do Trabalho do Rio de Janeiro',
  '0030': '30a Vara do Trabalho do Rio de Janeiro',
  '0031': '31a Vara do Trabalho do Rio de Janeiro',
  '0032': '32a Vara do Trabalho do Rio de Janeiro',
  '0033': '33a Vara do Trabalho do Rio de Janeiro',
  '0034': '34a Vara do Trabalho do Rio de Janeiro',
  '0035': '35a Vara do Trabalho do Rio de Janeiro',
  '0036': '36a Vara do Trabalho do Rio de Janeiro',
  '0037': '37a Vara do Trabalho do Rio de Janeiro',
  '0038': '38a Vara do Trabalho do Rio de Janeiro',
  '0039': '39a Vara do Trabalho do Rio de Janeiro',
  '0040': '40a Vara do Trabalho do Rio de Janeiro',
  '0041': '41a Vara do Trabalho do Rio de Janeiro',
  '0042': '42a Vara do Trabalho do Rio de Janeiro',
  '0043': '43a Vara do Trabalho do Rio de Janeiro',
  '0044': '44a Vara do Trabalho do Rio de Janeiro',
  '0045': '45a Vara do Trabalho do Rio de Janeiro',
  '0046': '46a Vara do Trabalho do Rio de Janeiro',
  '0047': '47a Vara do Trabalho do Rio de Janeiro',
  '0048': '48a Vara do Trabalho do Rio de Janeiro',
  '0049': '49a Vara do Trabalho do Rio de Janeiro',
  '0050': '50a Vara do Trabalho do  Rio de Janeiro',
  '0051': '51a Vara do Trabalho do Rio de Janeiro',
  '0052': '52a Vara do Trabalho do Rio de Janeiro',
  '0053': '53a Vara do Trabalho do Rio de Janeiro',
  '0054': '54a Vara do Trabalho do Rio de Janeiro',
  '0055': '55a Vara do Trabalho do Rio de Janeiro',
  '0056': '56a Vara do Trabalho do Rio de Janeiro',
  '0057': '57a Vara do Trabalho do Rio de Janeiro',
  '0058': '58a Vara do Trabalho do Rio de Janeiro',
  '0059': '59a Vara do Trabalho do Rio de Janeiro',
  '0060': '60a Vara do Trabalho do Rio de Janeiro',
  '0061': '61a Vara do Trabalho do Rio de Janeiro',
  '0062': '62a Vara do Trabalho do Rio de Janeiro',
  '0063': '63a Vara do Trabalho do Rio de Janeiro',
  '0064': '64a Vara do Trabalho do Rio de Janeiro',
  '0065': '65a Vara do Trabalho do  Rio de Janeiro',
  '0066': '66a Vara do Trabalho do Rio de Janeiro',
  '0067': '67a Vara do Trabalho do Rio de Janeiro',
  '0068': '68a Vara do Trabalho do Rio de Janeiro',
  '0069': '69a Vara do Trabalho do Rio de Janeiro',
  '0070': '70a Vara do Trabalho do Rio de Janeiro',
  '0071': '71a Vara do Trabalho do Rio de Janeiro',
  '0072': '72a Vara do Trabalho do Rio de Janeiro',
  '0073': '73a Vara do Trabalho do Rio de Janeiro',
  '0074': '74a Vara do Trabalho do Rio de Janeiro',
  '0075': '75a Vara do Trabalho do Rio de Janeiro',
  '0076': '76a Vara do Trabalho do Rio de Janeiro',
  '0077': '77a Vara do Trabalho do Rio de Janeiro',
  '0078': '78a Vara do Trabalho do  Rio de Janeiro',
  '0079': '79a Vara do Trabalho do Rio de Janeiro',
  '0080': '80a Vara do Trabalho do Rio de Janeiro',
  '0081': '81a Vara do Trabalho do Rio de Janeiro',
  '0082': '82a Vara do Trabalho do Rio de Janeiro',
  '0201': '1a Vara do Trabalho de  Duque de Caxias',
  '0202': '2a Vara do Trabalho de Duque de Caxias',
  '0203': '3a Vara do Trabalho de Duque de Caxias',
  '0204': '4a Vara do Trabalho de Duque de Caxias',
  '0205': '5a Vara do Trabalho de Duque de Caxias',
  '0206': '6a Vara do Trabalho de Duque de Caxias',
  '0207': '7a Vara do Trabalho de Duque de Caxias',
  '0221': '1a Vara do Trabalho de Nova Iguaçu',
  '0222': '2a Vara do Trabalho de Nova Iguaçu',
  '0223': '3a Vara do Trabalho de Nova Iguaçu',
  '0224': '4a Vara do Trabalho de Nova Iguaçu',
  '0225': '5a Vara do Trabalho de Nova Iguaçu',
  '0226': '6a Vara do Trabalho de Nova Iguaçu',
  '0241': '1a Vara do Trabalho de Niterói',
  '0242': '2a Vara do Trabalho de Niterói',
  '0243': '3a Vara do Trabalho de Niterói',
  '0244': '4a Vara do Trabalho de Niterói',
  '0245': '5a Vara do Trabalho de Niterói',
  '0246': '6a Vara do Trabalho de Niterói',
  '0247': '7a Vara do Trabalho de Niterói',
  '0261': '1a Vara do Trabalho de São Gonçalo',
  '0262': '2a Vara do Trabalho de São Gonçalo',
  '0263': '3a Vara do Trabalho de São Gonçalo',
  '0264': '4a Vara do Trabalho de São Gonçalo',
  '0281': '1a Vara do Trabalho de Campos dos Goytacazes',
  '0282': '2a Vara do Trabalho de  Campos dos Goytacazes',
  '0283': '3a Vara do Trabalho de  Campos dos Goytacazes',
  '0284': '4a Vara do Trabalho de  Campos dos Goytacazes',
  '0301': '1a Vara do Trabalho de Petrópolis',
  '0303': '2a Vara do Trabalho de Petrópolis',
  '0321': '1a Vara do Trabalho de São João de Meriti',
  '0322': '2a Vara do Trabalho de São João de Meriti',
  '0341': '1a Vara do Trabalho de Volta Redonda',
  '0342': '2a Vara do Trabalho de Volta Redonda',
  '0343': '3a Vara do Trabalho de Volta Redonda',
  '0401': '1a Vara do Trabalho de Angra dos Reis',
  '0411': '1a Vara do Trabalho de Araruama',
  '0421': '1a Vara de Barra do Piraí',
  '0431': '1a Vara do Trabalho de Cabo Frio',
  '0432': '2a Vara do Trabalho de Cabo Frio',
  '0441': '1a Vara do Trabalho de Cordeiro',
  '0451': '1a Vara do Trabalho de Itaborai',
  '0461': '1a Vara do Trabalho de Itaguai',
  '0471': '1a Vara do Trabalho de Itaperuna',
  '0481': '1a Vara do Trabalho de Macaé',
  '0482': '2a Vara do Trabalho de Macae',
  '0491': '1a Vara do Trabalho de Magé',
  '0501': '1a Vara do Trabalho de Nilópolis',
  '0511': '1a Vara do Trabalho de Nova Friburgo',
  '0521': '1a Vara do Trabalho de Resende',
  '0531': '1a Vara do Trabalho de Teresópolis',
  '0541': '1a Vara do Trabalho de Três Rios',
}