import { ModalClose } from '@mui/joy'


export default function MyModalClose() {
  return (
    <ModalClose
      variant='outlined'
      sx={{
        top: 'calc(-1/4 * var(--IconButton-size))',
        right: 'calc(-1/4 * var(--IconButton-size))',
        borderRadius: '50%',
      }}
    />
  )
}
