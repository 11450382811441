import { Box } from '@mui/material'
import FindCep from './FindAddressByCep'
import FindAddressByCep from './FindCepByAddress'
import MyBoxWithShadow from '../../MyBoxWithShadow'

export default function FindAddressPage() {
  return (
    <Box sx={{ display: 'flex', gap: '50px' }} alignSelf='center'>
      <MyBoxWithShadow>
        <FindCep />
      </MyBoxWithShadow>
      <MyBoxWithShadow>
        <FindAddressByCep />
      </MyBoxWithShadow>
    </Box>
  )
}
