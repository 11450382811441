import { User, onAuthStateChanged } from 'firebase/auth'
import { createContext, useEffect, useState } from 'react'
import { auth } from '../utils/firebase/firebaseStart'

interface IUserProvicerProps {
  children: React.ReactNode
}

interface IUserContext {
  currentUser: User | null
  // eslint-disable-next-line no-unused-vars
  setCurrentUser: (user: User | null) => void
}

export const UserContext = createContext<IUserContext>({
  currentUser: null,
  setCurrentUser: () => null,
})

export function UserProvider({ children }: IUserProvicerProps) {
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const context = { currentUser, setCurrentUser }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    })

    return unsubscribe
  }, [])

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>
}
