import { Box, Typography } from '@mui/joy'
import MyCard from '../../MyCard'
import Calendar from 'react-calendar'
import { Collapse } from '@mui/material'
import { useState } from 'react'
import monthsOfTheYear from '../../../utils/monthsOfTheYear'

interface IMonths {
    [index: number]: string
}

export default function MyCalendar() {
  const [collapse, setCollapse] = useState(false)
  const today = new Date()
  const months: IMonths = monthsOfTheYear
  return (
    <MyCard>
      <Box display='flex' flexDirection='column' alignItems='center'>
        <Typography level='h6' sx={{ color: 'white', mb: '10px' }}>
          <a onClick={() => setCollapse(!collapse)} style={{ cursor: 'pointer' }}>{ `${today.getDate()} de ${months[today.getMonth()]} de ${today.getFullYear()}` }</a>
        </Typography>
        <Collapse in={collapse}>
          <Calendar locale='pt-BR'/>
        </Collapse>
      </Box>
    </MyCard>
  )
}
