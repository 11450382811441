import { Box, Button, Input, Modal, ModalDialog, Typography } from '@mui/joy'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ClienteContext } from '../../context/ClientesContext'
import ClienteCard from '../ClienteCard'
import MyModalClose from '../MyModalClose'
import NewCliente from '../NewCliente'

export default function Clientes() {
  const { clientes } = useContext(ClienteContext)

  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [fetchLimit, setFecthLimit] = useState<number>(100)
  const [search, setSearch] = useState<string>('')
  const [clientesCount, setClientesCount] = useState(clientes.length)
  const [collapseAll, setCollapseAll] = useState(true)
  const [newClienteModal, setNewClienteModal] = useState<boolean>(false)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  useEffect(() => {
    isFetching ? fetchMoreClientes() : null
  }, [isFetching])

  useEffect(() => {
    setClientesCount(clientes.length)
  }, [clientes.length])

  function handleScroll() {
    // altura da janela visível do navegador + quantidade de pixels que foram rolados verticalmente na página, para fora da janela visível  !== altura total do documento HMTL
    if (
      window.innerHeight + document.documentElement.scrollTop !==
      document.documentElement.offsetHeight
    )
      return
    setIsFetching(true)
  }

  function fetchMoreClientes() {
    // setTimeout(() => {
    console.log('Fetch more clientes')
    setFecthLimit(fetchLimit + 50)
    setIsFetching(false)
    // }, 2000)
  }

  function sortClientesByName() {
    const sortedByName = clientes.sort((a, b) => {
      if (a.nome < b.nome) return -1
      if (a.nome > b.nome) return 1
      return 0
    })
    return sortedByName
  }

  const sortedClientes = sortClientesByName()

  function handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value
    setSearch(value)
  }

  return (
    <Fragment>
      <Modal open={newClienteModal} onClose={() => setNewClienteModal(false)}>
        <ModalDialog sx={{ backgroundColor: '#282c34' }}>
          <MyModalClose />
          <NewCliente />
        </ModalDialog>
      </Modal>

      <Box
        width='80%'
        display='flex'
        flexDirection='column'
        alignSelf='center'
        alignItems='center'
        gap='10px'
        sx={{
          backgroundColor: '#2d2e33',
          padding: '20px',
          boxShadow: '2px 2px 5px black',
          borderRadius: '20px',
          mb: '20px'
        }}
      >
        <Box
          display='flex'
          justifyContent='space-around'
          width='100%'
          padding='20px'
        >
          <Input
            placeholder={`Buscar... (${clientesCount}) clientes`}
            sx={{ width: '300px' }}
            value={search}
            onChange={(e) => handleSearch(e)}
          />
          <Button
            sx={{ ml: 'auto', mr: '10px' }}
            onClick={() => setNewClienteModal(true)}
          >
            +Novo Cliente
          </Button>
          <Button onClick={() => setCollapseAll(!collapseAll)}>
            {collapseAll ? 'Expandir tudo' : 'Recolher tudo'}
          </Button>
        </Box>
        <Box
          display='flex'
          flexDirection='row'
          flexWrap='wrap'
          gap='10px'
          alignItems='start'
          justifyContent='space-evenly'
        >
          {search
            ? sortedClientes
              .filter((cliente) =>
                cliente.nome
                  .toLocaleLowerCase()
                  .includes(search.toLocaleLowerCase())
              )
              .slice(0, 10)
              .map((cliente) => {
                return (
                  <ClienteCard
                    key={cliente.cpf}
                    cliente={cliente}
                    collapsed={collapseAll}
                  />
                )
              })
            : sortedClientes.slice(0, fetchLimit).map((cliente) => {
              return (
                <ClienteCard
                  key={cliente.cpf}
                  cliente={cliente}
                  collapsed={collapseAll}
                />
              )
            })}
        </Box>
        <Typography sx={{ color: 'white' }}>
          {isFetching && 'Carregando...'}
        </Typography>
      </Box>
    </Fragment>
  )
}
