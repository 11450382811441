import {
  Alert,
  Box,
  Modal,
  ModalClose,
  ModalDialog,
  Textarea,
  Typography,
} from '@mui/joy'
import MyCard from '../../MyCard'
import { IconButton } from '@mui/joy'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ClearIcon from '@mui/icons-material/Clear'
import DeleteIcon from '@mui/icons-material/Delete'
import { useEffect, useState } from 'react'
import { Collapse } from '@mui/material'
import BookIcon from '@mui/icons-material/Book'

export default function NotePad() {
  const [notes, setNotes] = useState<string>('')
  const [alertOpen, setAlertOpen] = useState<boolean>(false)
  const [hasLocalNotes1, setHasLocalNotes1] = useState<boolean>()
  const [hasLocalNotes2, setHasLocalNotes2] = useState<boolean>()
  const [hasLocalNotes3, setHasLocalNotes3] = useState<boolean>()
  const [localStorageKey, setLocalStorageKey] = useState<string>('')
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
  const [notesToShowInModal, setNotesToShowInModal] = useState<string | null>(
    ''
  )

  useEffect(() => {
    const textAreaNotes = localStorage.getItem('textAreaNotes')
    if (textAreaNotes) setNotes(textAreaNotes)
  }, [])

  useEffect(() => {
    if (alertOpen) setTimeout(() => setAlertOpen(false), 1000)
  }, [alertOpen])

  useEffect(() => {
    localStorage.getItem('localNotes1') ? setHasLocalNotes1(true) : null
    localStorage.getItem('localNotes2') ? setHasLocalNotes2(true) : null
    localStorage.getItem('localNotes3') ? setHasLocalNotes3(true) : null
  }, [hasLocalNotes1, hasLocalNotes2, hasLocalNotes3, localStorageKey])

  function saveToLocalNotes(
    setState: React.Dispatch<React.SetStateAction<boolean | undefined>>,
    state: boolean | undefined,
    key: string
  ) {
    setState(!state)
    setLocalStorageKey(key)
    const tempLocalNotes = localStorage.getItem(key)
    if (!state) {
      localStorage.setItem(key, notes)
    } else {
      setNotesToShowInModal(tempLocalNotes)
      setModalIsOpen(true)
    }
  }

  return (
    <MyCard width='calc(70vw)' maxWidth='400px'>
      <Collapse in={alertOpen}>
        <Alert>Copiado!</Alert>
      </Collapse>
      <Typography level='h6' sx={{ color: 'white', marginBottom: '10px' }}>
        NOTEPAD
      </Typography>

      {/* notepad text area */}
      <Textarea
        sx={{ maxWidth: '400px' }}
        value={notes || ''}
        onChange={(e) => {
          setNotes(e.target.value)
          localStorage.setItem('textAreaNotes', e.target.value)
        }}
      />
      <Box display='flex' gap='8px' sx={{ marginTop: '10px' }}>
        <IconButton
          size='sm'
          onClick={() => {
            setAlertOpen(true)
            navigator.clipboard.writeText(notes)
          }}
        >
          <ContentCopyIcon />
        </IconButton>
        <IconButton
          size='sm'
          onClick={() => {
            setNotes('')
            localStorage.setItem('textAreaNotes', '')
          }}
        >
          <ClearIcon />
        </IconButton>
        <IconButton
          disabled={notes || hasLocalNotes1 ? false : true}
          size='sm'
          color={hasLocalNotes1 ? 'success' : 'primary'}
          name='localNotes1'
          onClick={() =>
            saveToLocalNotes(setHasLocalNotes1, hasLocalNotes1, 'localNotes1')
          }
        >
          <BookIcon />1
        </IconButton>
        <IconButton
          disabled={notes || hasLocalNotes2 ? false : true}
          size='sm'
          color={hasLocalNotes2 ? 'success' : 'primary'}
          name='localNotes2'
          onClick={() =>
            saveToLocalNotes(setHasLocalNotes2, hasLocalNotes2, 'localNotes2')
          }
        >
          <BookIcon />2
        </IconButton>
        <IconButton
          disabled={notes || hasLocalNotes3 ? false : true}
          size='sm'
          name='localNotes3'
          color={hasLocalNotes3 ? 'success' : 'primary'}
          onClick={() =>
            saveToLocalNotes(setHasLocalNotes3, hasLocalNotes3, 'localNotes3')
          }
        >
          <BookIcon />3
        </IconButton>
        <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
          <ModalDialog>
            <ModalClose
              variant='outlined'
              sx={{
                top: 'calc(-1/4 * var(--IconButton-size))',
                right: 'calc(-1/4 * var(--IconButton-size))',
                borderRadius: '50%',
              }}
            />
            <Box display='flex' flexDirection='row' gap='8px'>
              <IconButton>
                <DeleteIcon
                  onClick={() => {
                    localStorage.setItem(localStorageKey, '')
                    setNotesToShowInModal('')
                    if (localStorageKey.includes('1')) {
                      setHasLocalNotes1(!hasLocalNotes1)
                    } else if (localStorageKey.includes('2')) {
                      setHasLocalNotes2(!hasLocalNotes2)
                    } else if (localStorageKey.includes('3')) {
                      setHasLocalNotes3(!hasLocalNotes3)
                    }
                    setModalIsOpen(false)
                  }}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(String(notesToShowInModal))
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Box>
            <pre>{notesToShowInModal}</pre>
          </ModalDialog>
        </Modal>
      </Box>
    </MyCard>
  )
}
