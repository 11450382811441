import { Button } from '@mui/joy'
import { AlignmentType, Document, Paragraph, TextRun } from 'docx'

import MyCard from '../../MyCard'
import { createFooter, createHeader, getNameInitials, saveDoc, whereAndWhen } from '../../../utils/docsHelper'
import { QualificacaoType } from './AutoDocsIndex'

interface IQualificacaoProps {
  qualificacao: QualificacaoType
}

export default function DeclaracaoHip({ qualificacao }: IQualificacaoProps): JSX.Element {
  function handleCreateDoc() {
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                bottom: `${4}cm`,
              },
            },
          },
          headers: createHeader(),

          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DECLARAÇÃO DE HIPOSSUFICIÊNCIA',
                  bold: true,
                  size: `${16}pt`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'Eu, ',
                  size: `${12}pt`
                }),
                new TextRun({
                  text: `${qualificacao.nome}`,
                  size: `${12}pt`,
                  bold: true
                }),
                new TextRun({
                  text: `, ${qualificacao.nacionalidade}, ${qualificacao.estadoCivil}, ${qualificacao.profissao}, nascido(a) em ${qualificacao.nascimento}, RG nº ${qualificacao.rg}, CPF nº ${qualificacao.cpf}, residente e domiciliado(a) à ${qualificacao.endereco},`,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ' DECLARO',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ', para todos os fins de direito e sob as penas da lei, que não tenho condições de arcar com as despesas decorrentes do presente processo, sem prejuízo do meu sustento e de minha família, razão pela qual se faz necessária a Gratuidade da Justiça, nos termos do artigo 98 e seguintes do Código de Processo Civil, fazendo-se necessário que o benefício abranja todos os atos do processo, inclusive emolumentos, despesas cartorárias e taxas.',
                  size: `${12}pt`,
                })

              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              alignment: AlignmentType.END,
              children: [
                new TextRun({
                  text: whereAndWhen('Campos dos Goytacazes/RJ', new Date()),
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: '____________________________________',
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${qualificacao.nome}`,
                  bold: true,
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `CPF ${qualificacao.cpf}`,
                  bold: true,
                  size: `${12}pt`,
                }),
              ],
            }),
          ],

          footers: createFooter()
        },
      ],
    })
    saveDoc(doc, `Declaracao-hip_${getNameInitials(qualificacao.nome)}`)
  }

  return (
    <MyCard>


      <Button onClick={handleCreateDoc}>GERAR DEC. DE HIPOSSUFICIÊNCIA</Button>
    </MyCard>
  )
}
