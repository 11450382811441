import { Box } from '@mui/joy'
import Procuracao from './Procuracao'
import Qualificacao from './Qualificacao'
import { useState } from 'react'
import DeclaracaoHip from './DeclaracaoHip'
import Contrato from './Contrato'
import MyBoxWithShadow from '../../MyBoxWithShadow'

export interface QualificacaoType {
  nome: string
  nacionalidade: string
  estadoCivil?: string
  profissao: string
  nascimento: string
  rg: string
  cpf: string
  endereco: string
}

export default function AutoDocsIndex() {
  const qualificacaoInitialState = {
    nome: '',
    nacionalidade: '',
    estadoCivil: '',
    profissao: '',
    nascimento: '',
    rg: '',
    cpf: '',
    endereco: '',
  }

  const [qualificacao, setQualificacao] = useState<QualificacaoType>(
    qualificacaoInitialState
  )

  const [finalidade, setFinalidade] = useState<string>('')

  function resetQualificacao() {
    setQualificacao(qualificacaoInitialState)
  }

  return (
    <Box display='flex' gap='20px' alignSelf='center'>
      <MyBoxWithShadow>
        <Qualificacao
          qualificacao={qualificacao}
          setQualificacao={setQualificacao}
          resetQualificacao={resetQualificacao}
        />
      </MyBoxWithShadow>

      <MyBoxWithShadow>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='space-evenly'
          gap='10px'
        >
          <DeclaracaoHip qualificacao={qualificacao} />
          <Procuracao
            qualificacao={qualificacao}
            finalidade={finalidade}
            setFinalidade={setFinalidade}
          />
          <Contrato
            qualificacao={qualificacao}
            finalidade={finalidade}
            setFinalidade={setFinalidade}
          />
        </Box>
      </MyBoxWithShadow>
    </Box>
  )
}
