import { Box, Button, Input, Typography } from '@mui/joy'
import MyCard from '../../MyCard'
import { auth } from '../../../utils/firebase/firebaseStart'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { Fragment, useContext, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import Calculator from './Calculator'
import SearchProcess from './SearchProcess'
import NotePad from './NotePad'
import SearchClient from './SearchClient'
import 'react-calendar/dist/Calendar.css'
import MyCalendar from './MyCalendar'
import MyBoxWithShadow from '../../MyBoxWithShadow'

interface IUser {
  credentials: object
  email: string
  pwd: string
}

export default function HomePage() {
  const [user, setUser] = useState<IUser>({
    credentials: {},
    email: '',
    pwd: '',
  })

  const context = useContext(UserContext)

  function signInUser() {
    let credentials = {}
    signInWithEmailAndPassword(auth, user.email, user.pwd)
      .then((userCredential) => {
        credentials = userCredential.user
        setUser({ ...user, credentials: credentials })
      })
      .catch((error) => console.log('failed to sign in', error.message))
  }

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUser({ ...user, [e.target.name]: e.target.value })
  }

  return (
    <Fragment>
      <Box display='flex' flexDirection='column' gap='10px'>
        {context.currentUser ? (
          // user is logged in
          <Box
            display='flex'
            flexDirection='row'
            gap='20px'
            flexWrap='wrap'
            alignItems='start'
            justifyContent='center'
          >
            <Box
              display='flex'
              flexDirection='column'
              gap='10px'
              alignItems='center'
              sx={{
                backgroundColor: '#2d2e33',
                padding: '20px',
                boxShadow: '2px 2px 5px black',
                borderRadius: '20px',
              }}
            >
              <SearchProcess />
              <SearchClient />
            </Box>
            <MyBoxWithShadow>
              <MyCalendar />
              <Calculator />
              <NotePad />
            </MyBoxWithShadow>
          </Box>
        ) : (
          // user is not logged in
          <Box
            display='flex'
            justifyContent='center'
            alignItems='center'
            alignContent='center'
            height='60vh'
          >
            <MyBoxWithShadow>
              <MyCard width='300px'>
                <Box display='flex' flexDirection='column' gap='10px'>
                  <Typography level='h3' sx={{ color: 'white' }}>
                    FAÇA LOGIN
                  </Typography>
                  <Input
                    placeholder='email'
                    name='email'
                    value={user.email}
                    onChange={handleOnChange}
                  />
                  <Input
                    placeholder='senha'
                    name='pwd'
                    type='password'
                    onChange={handleOnChange}
                  />
                  <Button onClick={signInUser}>LOGIN</Button>
                </Box>
              </MyCard>
            </MyBoxWithShadow>
          </Box>
        )}
      </Box>
    </Fragment>
  )
}
