import { createContext, useEffect, useState } from 'react'
import { ICliente } from '../components/ClienteCard' 
import { doc, getDoc } from 'firebase/firestore'
import { db } from '../utils/firebase/firebaseStart'
// import { clientes as clientesWithId } from '../utils/clientesWithId'

export const ClienteContext = createContext<{
  clientes: ICliente[]
  setClientes: React.Dispatch<React.SetStateAction<ICliente[]>>
  getClientesFromDBAndSetIntoState: () => void
    }>({
      clientes: [],
      setClientes: () => [],
      getClientesFromDBAndSetIntoState: () => null,
    })

export function ClienteProvider({ children }: { children: React.ReactNode }) {
  const [clientes, setClientes] = useState<ICliente[]>([])
  const docRef = doc(db, 'advutils', 'clientes')

  async function getClientesFromDBAndSetIntoState() {
    try{
      const docsSnap = await getDoc(docRef)
      if (docsSnap.exists()) {
        setClientes(docsSnap.data().items)
      }
    } catch(error: unknown) {
      console.log(error)
    }
  }

  useEffect(() => {
    getClientesFromDBAndSetIntoState()
    // addCollectionAndDocuments('advutils', 'clientes', clientesWithId )
  }, [])

  const context = { clientes, setClientes, getClientesFromDBAndSetIntoState }

  return (
    <ClienteContext.Provider value={context}>
      {children}
    </ClienteContext.Provider>
  )
}
