import { Box, Input, Typography } from '@mui/joy'
import { Fragment, useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import { IconButton } from '@mui/joy'
import { IconButton as MaterialIconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import SaveIcon from '@mui/icons-material/Save'
import StarIcon from '@mui/icons-material/Star'
import AddBoxIcon from '@mui/icons-material/AddBox'
import {
  doc,
  getDoc,
  setDoc,
} from 'firebase/firestore'
import { db } from '../../../utils/firebase/firebaseStart'
import { v4 as uuid } from 'uuid'
import { Menu, MenuItem } from '@mui/material'
import './CustomLinks.css'

interface ILink {
  title: string
  url: string
  id: string
}

export default function CustomLinks() {
  const [links, setLinks] = useState<Array<ILink>>([])
  const [edit, setEdit] = useState<boolean>(false)

  //   const collectionRef = collection(db, 'advutils')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  useEffect(() => {
    async function getLinksFromDBToSetInstoState() {
      try {
        const docRef = doc(db, 'advutils', 'links')
        const docSnap = await getDoc(docRef)
        if (docSnap.exists()) {
          setLinks(docSnap.data().items)
        } else {
          console.log('Documento não encontrado no BD')
        }
      } catch (error) {
        console.log(error)
      }
    }
    getLinksFromDBToSetInstoState()
  }, [])

  useEffect(() => {
    if (links.length) {
      saveLink()
    }
  }, [links])

  function handleEditClick() {
    // addDoc(collection(db, 'links'),  links[0])
    setEdit(!edit)
  }

  function handleAddClick() {
    setEdit(true)
    // links.map(() => setLinks([...links, { title: '', url: '', id: uuid() }]))
    // setLinks(links.concat({ title: '', url: '', id: uuid() }))
    setLinks((links) => [...links, { title: '', url: '', id: uuid() }])
  }

  async function handleDeleteClick(id: string) {
    setEdit(true)
    setLinks(() => links.filter((link) => link.id !== id))
  }

  function handleEditLink(
    e: React.ChangeEvent<HTMLInputElement>,
    id: string,
    name: string
  ) {
    setLinks((links) =>
      links.map((link) => {
        if (link.id === id) {
          if (name === 'inputURL') {
            return { ...link, url: e.target.value }
          } else if (name === 'inputTitle') {
            return { ...link, title: e.target.value }
          }
        }
        return link
      })
    )
  }

  async function saveLink() {
    const docRef = doc(db, 'advutils', 'links')
    await setDoc(docRef, { items: links })
  }

  return (
    // <MyCard width='300px' maxHeight='400px'>
    <Fragment>
      <Typography
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
        alignSelf='center'
      >
        <MaterialIconButton>
          <StarIcon sx={{ alignSelf: 'center', color: 'white' }} />
        </MaterialIconButton>
      </Typography>
      <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {links.map((link) => (
          <MenuItem key={link.id}>
            {edit ? (
              <Box display='flex' gap='8px'>
                <Input
                  name='inputTitle'
                  value={link.title.toLocaleUpperCase()}
                  onChange={(e) => handleEditLink(e, link.id, e.target.name)}
                  onBlur={saveLink}
                  sx={{ width: '8em' }}
                />
                <Input
                  name='inputURL'
                  value={link.url}
                  onChange={(e) => handleEditLink(e, link.id, e.target.name)}
                  onBlur={saveLink}
                  endDecorator={
                    <IconButton onClick={() => handleDeleteClick(link.id)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                />
              </Box>
            ) : (
              <a
                href={link.url}
                target='_blank'
                rel='noreferrer'
                style={{ color: 'white', textDecoration: 'none' }}
              >
                {link.title.toLocaleUpperCase()}
              </a>
            )}
          </MenuItem>
        ))}
        <MaterialIconButton
          sx={{ marginLeft: '8px' }}
          onClick={handleEditClick}
        >
          {edit ? (
            <SaveIcon sx={{ color: 'white' }} />
          ) : (
            <EditIcon sx={{ color: 'white' }} />
          )}
        </MaterialIconButton>
        <MaterialIconButton sx={{ marginLeft: '8px' }} onClick={handleAddClick}>
          <AddBoxIcon sx={{ color: 'white' }} />
        </MaterialIconButton>
      </Menu>
    </Fragment>
  )
}
