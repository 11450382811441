import { Card, Input, Box, Button, Textarea, IconButton } from '@mui/joy'
import { Collapse, Typography } from '@mui/material'
import { useState } from 'react'
import OpenInFullIcon from '@mui/icons-material/OpenInFull'
import MyCard from '../../MyCard'

interface Address {
  logradouro: string
  cidade: string
  uf: string
  cep: string
}

export default function FindAddressByCep() {
  const [address, setAddress] = useState<Address>({
    logradouro: '',
    cidade: '',
    uf: '',
    cep: '',
  })

  const [openForm, setOpenForm] = useState<boolean>(true)
  const [resultMaxRows, setResultMaxRows] = useState<number>(3)

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    const fieldToUpdate = e.target.name
    setAddress({ ...address, [fieldToUpdate]: e.target.value })
  }

  async function findAddressByCep() {
    try {
      const response = await fetch(
        `https://viacep.com.br/ws/${address.uf}/${address.cidade}/${address.logradouro}/json/`
      )
      const result = await response.json()
      let ceps = result.reduce((acc: string, item: any) => {
        return `${acc} ${item.cep} (${item.logradouro})${
          item.complemento ? ' \n ' + item.complemento : ''
        }\n\n`
      }, '')

      //delete \n\n in the end of string
      ceps = ceps.replace(/\n\n$/, '')
      console.log('-------------ceps-----------\n', ceps)

      setAddress({ ...address, cep: ceps })
    } catch (error) {
      console.log(error)
      setAddress({ ...address, cep: 'Não encontrado' })
    }
  }

  function handleCleanClick() {
    setAddress({
      logradouro: '',
      cidade: '',
      uf: '',
      cep: '',
    })
  }

  return (
    <MyCard>
      <Typography variant="h4" sx={{ color: 'white' }}>
        CEP pelo Endereço
      </Typography>
      <Collapse in={openForm}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            justifyContent: 'space-between',
            marginTop: '12px',
          }}
        >
          <Input
            placeholder="Logradouro"
            onChange={handleOnChange}
            name="logradouro"
            value={address.logradouro}
          ></Input>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '10px',
              justifyContent: 'space-between',
            }}
          >
            <Input
              placeholder="Cidade"
              onChange={handleOnChange}
              name="cidade"
              value={address.cidade}
              sx={{ width: '100%' }}
            ></Input>
            <Input
              placeholder="UF"
              onChange={handleOnChange}
              name="uf"
              value={address.uf}
              sx={{ width: '50px' }}
            ></Input>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
            <Button
              startDecorator="🔎"
              onClick={findAddressByCep}
              disabled={
                address.logradouro || address.cidade || address.uf
                  ? false
                  : true
              }
            >
              Buscar
            </Button>
            <Button
              startDecorator="🔎"
              onClick={handleCleanClick}
              disabled={
                address.logradouro || address.cidade || address.uf
                  ? false
                  : true
              }
            >
              Limpar
            </Button>
          </Box>
        </Box>
      </Collapse>
      <Textarea
        startDecorator={
          <IconButton
            disabled={
              address.logradouro || address.cidade || address.uf ? false : true
            }
            onClick={() => {
              setOpenForm(!openForm)
              setResultMaxRows(resultMaxRows === 3 ? 10 : 3)
            }}
          >
            <OpenInFullIcon fontSize="small" />
          </IconButton>
        }
        placeholder="CEP"
        readOnly={true}
        value={address.cep}
        maxRows={resultMaxRows}
        sx={{ background: 'lightgrey', marginTop: '10px' }}
      ></Textarea>
    </MyCard>
  )
}
