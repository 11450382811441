import { Box, IconButton, Input, Tooltip, Typography } from '@mui/joy'
import { useEffect, useState } from 'react'
import { tjrjCodes, trfCodes, trtCodes } from '../../../utils/processCodes'
import SearchIcon from '@mui/icons-material/Search'
import MyCard from '../../MyCard'

export default function SearchProcess() {
  const [processNumber, setProcessNumber] = useState<string>('')
  const [origin, setOrigin] = useState<string | null>(null)
  const [disableSearchButon, setDisableSearchButton] = useState<boolean>(true)

  useEffect(() => {
    getOrigin()
    processNumber.length === 25
      ? setDisableSearchButton(false)
      : setDisableSearchButton(true)
  }, [processNumber])

  function getOrigin() {
    const origin = processNumber.slice(-4)
    const result = tjrjCodes[origin]
      ? `TJRJ - ${tjrjCodes[origin]}`
      : trtCodes[origin]
        ? `TRT1 - ${trtCodes[origin]}`
        : trfCodes[origin]
          ? `TRF2 - ${trfCodes[origin]}`
          : null

    setOrigin(result)
  }

  function searchProcess() {
    navigator.clipboard.writeText(processNumber)

    switch (origin?.slice(0, 4)) {
      case 'TJRJ':
        if (Number(processNumber.slice(1, 2)) >= 3) {
          window.open(
            'https://tjrj.pje.jus.br/1g/ConsultaPublica/listView.seam',
            '_blank'
          )
        } else {
          window.open(
            `https://www3.tjrj.jus.br/consultaprocessual/#/consultapublica?numProcessoCNJ=${processNumber}`,
            '_blank'
          )
        }
        break
      case 'TRT1':
        window.open(
          `https://pje.trt1.jus.br/consultaprocessual/detalhe-processo/${processNumber}/1`
        )
        break
      case 'TRF2':
        window.open(
          `https://eproc.jfrj.jus.br/eproc/externo_controlador.php?acao=processo_seleciona_publica&acao_origem=processo_consulta_publica&acao_retorno=processo_consulta_publica&num_processo=${processNumber}`
        )
        break
      default:
        window.alert('Desculpe, não foi possível completar a consulta.')
    }
  }

  return (
    <MyCard width='70vw' maxWidth='400px'>
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography
          level='h6'
          sx={{ color: 'white' }}
        >
          CONSULTA PÚBLICA DE PROCESSOS
        </Typography>
        <Tooltip placement='top-end' title='Apenas TJRJ, TRT1 (RJ) e TRF2 (RJ)'>
          <Input
            placeholder='0000000-00.0000.0.00.0000'
            value={processNumber}
            onChange={(e) => {
              e.target.maxLength = 25
              let value = e.target.value
              value = value.replace(/\D/g, '')
              value = value.replace(
                /(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/g,
                '$1-$2.$3.$4.$5.$6'
              )

              setProcessNumber(value)
            }}
            endDecorator={
              <IconButton
                variant='solid'
                disabled={disableSearchButon}
                onClick={searchProcess}
              >
                <SearchIcon />
              </IconButton>
            }
          />
        </Tooltip>
        {processNumber.length === 25 ? (
          <Typography level='body3' sx={{ color: 'whitesmoke' }}>
            {origin}
          </Typography>
        ) : null}
      </Box>
    </MyCard>
  )
}
