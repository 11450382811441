import './App.css'
import FixCarriage from './components/pages/fix-carriage/FixCarriage'
import { Routes, Route } from 'react-router-dom'
import MyAppBar from './components/my-app-bar/MyAppBar'
import FindAddressPage from './components/pages/find-address/FindAddressPage'
import AutoDocsIndex from './components/pages/auto-docs/AutoDocsIndex'
import HomePage from './components/pages/home-page/HomePage'
import { useContext } from 'react'
import { UserContext } from './context/UserContext'
import { Box } from '@mui/joy'
import Clientes from './components/pages/ClientesList'

function App() {
  const context = useContext(UserContext)

  function privateRoute(component: React.ReactElement) {
    // eslint-disable-next-line no-constant-condition
    if (context.currentUser) {
      return component
    }
    return null
  }

  return (
    <div className='App'>
      {/* <div className='App-body'> */}
      <Box
        minHeight='100vh'
        display='flex'
        flexDirection='column'
        sx={{ backgroundColor: '#282c34' }}
      >
        <MyAppBar />
        <Routes>
          <Route index element={HomePage()} />
          <Route path='/clientes' element={privateRoute(Clientes())} />
          <Route path='/buscacep' element={privateRoute(FindAddressPage())} />
          <Route path='/autodocs' element={privateRoute(AutoDocsIndex())} />
          <Route path='/fixcarriage' element={privateRoute(FixCarriage())} />
        </Routes>
      </Box>
      {/* </div> */}
    </div>
  )
}

export default App
