import { Card } from '@mui/joy'
import { OverridableTypeMap } from '@mui/material/OverridableComponent'
import { DefaultComponentProps } from '@mui/material/OverridableComponent'

export default function MyCard({
  width='70vw',
  maxWidth='400px',
  children,
  maxHeight,
  height='fit-content'
}: DefaultComponentProps<OverridableTypeMap>) {
  return (
    <Card size='lg' sx={{ background: '#1976d25e', height: height, width: width, maxWidth: maxWidth, maxHeight: maxHeight, overflow:'auto' }}>
      {children}
    </Card>
  )
}
