import {
  Box,
  Button,
  IconButton,
  Input,
  List,
  ListItem,
  Modal,
  ModalDialog,
  Textarea,
  Typography,
} from '@mui/joy'

import MyCard from './MyCard'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ClienteContext } from '../context/ClientesContext'
import { Collapse } from '@mui/material'
import { db } from '../utils/firebase/firebaseStart'
import { doc, setDoc } from 'firebase/firestore'
import MyModalClose from './MyModalClose'

export interface ICliente {
  id: string
  nome: string
  nacionalidade: string
  estadoCivil?: string
  profissao: string
  nascimento: string
  rg: string
  cpf: string
  endereco: string
}

export default function ClienteCard({
  cliente: {
    id,
    nome,
    nacionalidade,
    estadoCivil,
    profissao,
    nascimento,
    rg,
    cpf,
    endereco,
  },
  collapsed,
}: {
  cliente: ICliente
  collapsed: boolean
}) {
  const [cliente, setCliente] = useState<ICliente>({
    id,
    nome,
    nacionalidade,
    estadoCivil,
    profissao,
    nascimento,
    rg,
    cpf,
    endereco,
  })
  const [clienteBeforeEditing, setClienteBeforeEditing] =
    useState<ICliente | null>(null)
  const [open, setOpen] = useState<boolean>(!collapsed)
  const [editingCliente, setEditingCliente] = useState<boolean>(false)
  const [confirmDeleteModal, setConfirmDeleteModal] = useState<boolean>(false)
  const docRef = doc(db, 'advutils', 'clientes')

  const clienteContext = useContext(ClienteContext)

  useEffect(() => {
    setOpen(!collapsed)
  }, [collapsed])

  function handleEditCliente() {
    setClienteBeforeEditing({ ...cliente })
    setEditingCliente(!editingCliente)
  }

  function handleCancelEditingCliente() {
    clienteBeforeEditing ? setCliente({ ...clienteBeforeEditing }) : null
    setEditingCliente(false)
  }

  async function handleSaveCliente() {
    const clienteIndex = clienteContext.clientes.indexOf(
      clienteContext.clientes.filter((item) => item.id === cliente.id)[0]
    )
    const clientesCopy = [...clienteContext.clientes]
    clientesCopy[clienteIndex] = cliente
    clienteContext.setClientes(clientesCopy)

    await setDoc(docRef, { items: clientesCopy })
    setEditingCliente(false)
  }

  async function handleDeleteCliente() {
    const clientesFiltered = clienteContext.clientes.filter((item) => item.id !== cliente.id)
    clienteContext.setClientes(clientesFiltered)
    await setDoc(docRef, { items: clientesFiltered } )
    
    setConfirmDeleteModal(false)
  }

  function handleOnChange(
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    const elementName = e.target.name
    switch (elementName) {
      case 'nome': {
        console.log(elementName)
        setCliente({ ...cliente, nome: e.target.value })
        break
      }

      case 'nacionalidade': {
        console.log(elementName)
        setCliente({ ...cliente, nacionalidade: e.target.value })
        break
      }

      case 'estadoCivil': {
        console.log(elementName)
        setCliente({ ...cliente, estadoCivil: e.target.value })
        break
      }

      case 'profissao': {
        console.log(elementName)
        setCliente({ ...cliente, profissao: e.target.value })
        break
      }

      case 'nascimento': {
        console.log(elementName)
        setCliente({ ...cliente, nascimento: e.target.value })
        break
      }

      case 'rg': {
        console.log(elementName)
        setCliente({ ...cliente, rg: e.target.value })
        break
      }

      case 'cpf': {
        console.log(elementName)
        setCliente({ ...cliente, cpf: e.target.value })
        break
      }

      case 'endereco': {
        console.log(elementName)
        setCliente({ ...cliente, endereco: e.target.value })
        break
      }
    }
  }

  return (
    <Fragment>
      <Modal
        open={confirmDeleteModal}
        onClose={() => setConfirmDeleteModal(false)}
      >
        <ModalDialog>
          <MyModalClose />
          <Typography>
            {`Essa ação não pode ser desfeita. Tem certeza que deseja excluir ${cliente.nome}?`}
          </Typography>
          <Box display='flex' gap='10px' justifyContent='flex-end' margin='20px'>
            <Button variant='outlined' color='neutral' onClick={() => setConfirmDeleteModal(false)}>
              Cancelar
            </Button>
            <Button color='danger' onClick={handleDeleteCliente}>Confirmar</Button>
          </Box>
        </ModalDialog>
      </Modal>
      <MyCard width='300px' overflow='none'>
        <Typography
          level='h5'
          sx={{ color: 'whitesmoke', cursor: 'pointer', fontSize: '12px' }}
          marginBottom='10px'
          endDecorator={
            open ? (
              editingCliente ? (
                <Box display='flex' gap='8px'>
                  <IconButton onClick={handleSaveCliente}>salvar</IconButton>
                  <IconButton onClick={handleCancelEditingCliente}>
                    cancelar
                  </IconButton>
                </Box>
              ) : (
                <Box>
                  <IconButton
                    size='sm'
                    variant='plain'
                    onClick={handleEditCliente}
                    sx={{ color: 'yellowgreen', padding: '5px' }}
                  >
                    editar
                  </IconButton>

                  <IconButton
                    size='sm'
                    variant='plain'
                    onClick={() => setConfirmDeleteModal(true)}
                    sx={{ color: 'red', padding: '5px' }}
                  >
                    -excluir
                  </IconButton>
                </Box>
              )
            ) : (
              ''
            )
          }
        >
          {!editingCliente ? (
            <a onClick={() => setOpen(!open)}>{cliente.nome}</a>
          ) : (
            <Input
              placeholder='NOME'
              value={cliente.nome.toLocaleUpperCase()}
              onChange={(e) => handleOnChange(e)}
              name='nome'
            />
          )}
        </Typography>
        <Collapse in={open}>
          <List sx={{ borderRadius: '20px', m: '10px 0px 10px 0' }}>
            <ListItem sx={{ color: 'white', textAlign: 'left' }}>
              {!editingCliente ? (
                cliente.nacionalidade ? (
                  'Nacionalidade: ' + cliente.nacionalidade.toLocaleLowerCase()
                ) : (
                  'Nacionalidade: não informada'
                )
              ) : (
                <Input
                  placeholder='NACIONALIDADE'
                  value={cliente.nacionalidade.toLocaleUpperCase()}
                  onChange={(e) => handleOnChange(e)}
                  name='nacionalidade'
                />
              )}
            </ListItem>
            <ListItem sx={{ color: 'white', textAlign: 'left' }}>
              {!editingCliente ? (
                cliente.profissao ? (
                  'Profissão: ' + cliente.profissao.toLocaleLowerCase()
                ) : (
                  'Profissão: não informada'
                )
              ) : (
                <Input
                  placeholder='PROFISSAO'
                  value={cliente.profissao.toLocaleUpperCase()}
                  onChange={(e) => handleOnChange(e)}
                  name='profissao'
                />
              )}
            </ListItem>

            <ListItem sx={{ color: 'white', textAlign: 'left' }}>
              {!editingCliente ? (
                cliente.estadoCivil ? (
                  'Estado civil: ' + cliente.estadoCivil
                ) : (
                  'Estado civil: não informado'
                )
              ) : (
                <Input
                  placeholder='ESTADO CIVIL'
                  value={cliente.estadoCivil}
                  onChange={(e) => handleOnChange(e)}
                  name='estadoCivil'
                />
              )}
            </ListItem>

            <ListItem sx={{ color: 'white', textAlign: 'left' }}>
              {!editingCliente ? (
                cliente.nascimento ? (
                  `Nascido(a) em ${cliente.nascimento}`
                ) : (
                  'Nascimento: não informado'
                )
              ) : (
                <Input
                  placeholder='NASCIMENTO DD/MM/AAAA'
                  value={cliente.nascimento}
                  onChange={(e) => handleOnChange(e)}
                  name='nascimento'
                />
              )}
            </ListItem>
            <ListItem sx={{ color: 'white', textAlign: 'left' }}>
              {!editingCliente ? (
                cliente.rg ? (
                  `RG: ${cliente.rg}`
                ) : (
                  'RG: não informado'
                )
              ) : (
                <Input
                  placeholder='RG'
                  value={cliente.rg}
                  onChange={(e) => handleOnChange(e)}
                  name='rg'
                />
              )}
            </ListItem>
            {cliente.cpf !== 'nao encontrado' && (
              <ListItem sx={{ color: 'white', textAlign: 'left' }}>
                {!editingCliente ? (
                  cliente.cpf ? (
                    `CPF: ${cliente.cpf}`
                  ) : (
                    'CPF: não informado'
                  )
                ) : (
                  <Input
                    placeholder='CPF'
                    value={cliente.cpf}
                    onChange={(e) => handleOnChange(e)}
                    name='cpf'
                  />
                )}
              </ListItem>
            )}
            {cliente.endereco !== 'nao encontrado' && (
              <ListItem sx={{ color: 'white', textAlign: 'left' }}>
                {!editingCliente ? (
                  cliente.endereco ? (
                    `Endereço: ${cliente.endereco}`
                  ) : (
                    'Endereço: não informado'
                  )
                ) : (
                  <Textarea
                    placeholder='ENDEREÇO'
                    value={cliente.endereco}
                    onChange={(e) => handleOnChange(e)}
                    name='endereco'
                  />
                )}
              </ListItem>
            )}
          </List>
        </Collapse>
      </MyCard>
    </Fragment>
  )
}
