import {
  Box,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Typography,
} from '@mui/joy'
import MyCard from '../../MyCard'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ClienteContext } from '../../../context/ClientesContext'
import { ICliente } from '../../ClienteCard' 

export default function SearchClient() {
  const { clientes, getClientesFromDBAndSetIntoState } =
    useContext(ClienteContext)

  const [result, setResult] = useState<ICliente>()
  const [search, setSearch] = useState<string>('')
  const [modalOpen, setModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (!clientes.length) {
      getClientesFromDBAndSetIntoState()
    }
    setResult(
      clientes.filter((cliente) =>
        cliente.nome.toLocaleLowerCase().includes(search.toLocaleLowerCase())
      )[0]
    )
  }, [search])

  function handleMoreDetails() {
    setModalOpen(true)
  }
  return (
    <Fragment>
      {/* MODAL START */}
      <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
        <ModalDialog>
          <ModalClose
            variant='outlined'
            sx={{
              top: 'calc(-1/4 * var(--IconButton-size))',
              right: 'calc(-1/4 * var(--IconButton-size))',
              borderRadius: '50%',
            }}
          />
          <Typography level='h5'>{result?.nome}</Typography>
          {result?.nacionalidade && <Typography>{result.nacionalidade} </Typography>}
          {result?.profissao && <Typography>{result.profissao} </Typography>}
          {result?.rg && <Typography>RG nº {result.rg} </Typography>}
          {result?.cpf && <Typography>CPF nº {result.cpf} </Typography>}
          {result?.endereco && <Typography>Endereço {result.endereco} </Typography>}
        </ModalDialog>
      </Modal>

      {/* MYCARD START */}
      <MyCard maxWidth='400px' width='70vw'>
        <Box display='flex' flexDirection='column' gap='10px'>
          <Typography level='h6' sx={{ color: 'white' }}>
            BUSCA CLIENTE
          </Typography>

          <Input
            placeholder='NOME DO CLIENTE'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {search && (
            <Input
              placeholder='...'
              readOnly={true}
              value={!search ? '' : result ? result.nome : ''}
              sx={{ background: 'lightgrey' }}
              endDecorator={
                <Typography sx={{ ml: 'auto' }}>
                  {result && (
                    <IconButton onClick={handleMoreDetails}>+</IconButton>
                  )}
                </Typography>
              }
            />
          )}
        </Box>
      </MyCard>
    </Fragment>
  )
}
