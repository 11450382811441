import {
  Document,
  Footer,
  Header,
  ImageRun,
  Packer,
  Paragraph,
  TextRun,
} from 'docx'
import { saveAs } from 'file-saver'
import docHeader from '../components/pages/auto-docs/templates/docHeader'
import docFooter from '../components/pages/auto-docs/templates/docFooter'
import { ISectionOptions } from 'docx'
import monthsOfTheYear from './monthsOfTheYear'
import daysOfTheWeek from './daysOfTheWeek'

interface MonthsOfTheYear {
  [key: number]: string
}

interface DaysOfTheWeek {
  [key: number]: string
}

export function createHeader(): ISectionOptions['headers'] {
  const headers: ISectionOptions['headers'] = {
    default: new Header({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: Uint8Array.from(window.atob(docHeader), (c) =>
                c.charCodeAt(0)
              ),
              transformation: {
                width: 793.7,
                height: 79.3700787411,
              },
              floating: {
                horizontalPosition: {
                  offset: 0,
                },
                verticalPosition: {
                  offset: 0,
                },
              },
            }),
          ],
        }),
      ],
    }),
  }
  return headers
}

export function createFooter(): ISectionOptions['footers'] {
  const footers: ISectionOptions['footers'] = {
    default: new Footer({
      children: [
        new Paragraph({
          children: [
            new ImageRun({
              data: Uint8Array.from(window.atob(docFooter), (c) =>
                c.charCodeAt(0)
              ),
              transformation: {
                width: 793.7,
                height: 162.8976378,
              },
              floating: {
                horizontalPosition: {
                  offset: 0,
                },
                verticalPosition: {
                  offset: 9200000,
                },
              },
            }),
          ],
        }),
      ],
    }),
  }
  return footers
}

export function createNormalText(text: string, size = 12): TextRun {
  const normalText = new TextRun({
    text: text,
    size: `${size}pt`,
  })
  return normalText
}

export function createBoldText(text: string, size = 12): TextRun {
  const boldText = new TextRun({
    text: text,
    size: `${size}pt`,
    bold: true,
  })
  return boldText
}

export function jumpLine(): Paragraph {
  return new Paragraph({})
}

export function saveDoc(doc: Document, name: string): void {
  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, name)
  })
}

export function whereAndWhen(where: string, when: Date) {
  const months: MonthsOfTheYear = monthsOfTheYear
  const days: DaysOfTheWeek = daysOfTheWeek

  return `${where}, ${days[when.getDay()]}, ${when.getDate()} de ${
    months[when.getMonth()]
  } de ${when.getFullYear()}.`
}

export function getNameInitials(name: string): string {
  const regex = /\b\w/gu //macthes the first letter of each name
  const initials = name.match(regex)?.toString().replaceAll(',', '')
  return initials ? initials : ''
}
