import * as React from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'
import Container from '@mui/material/Container'
import MenuItem from '@mui/material/MenuItem'
import HandymanIcon from '@mui/icons-material/Handyman'
import { Link, useNavigate } from 'react-router-dom'
import { UserContext } from '../../context/UserContext'
import { signOut } from 'firebase/auth'
import { auth } from '../../utils/firebase/firebaseStart'
import CustomLinks from '../pages/home-page/CustomLinks'

const pages = [
  { title: 'HOME', route: '/' },
  { title: 'CLIENTES', route: '/clientes' },
  { title: 'AUTO DOCS', route: '/autodocs' },
  { title: 'BUSCA CEP', route: '/buscacep' },
  { title: 'FIX CARRIAGE', route: '/fixcarriage' },
]

export default function MyAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  )
  const context = React.useContext(UserContext)

  const navigate = useNavigate()

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseNavMenu = (route: string) => {
    setAnchorElNav(null)
    if (route) {
      // navigate(route)
    }
  }

  const handleCloseUserMenu = () => {
    setAnchorElUser(null)
  }

  return (
    <AppBar position='static' sx={{ marginBottom: '10px' }}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <HandymanIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant='h6'
            noWrap
            // component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to='/' style={{ color: 'white' }}>
              ADVUTILS
            </Link>
          </Typography>

          {context.currentUser ? (
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size='large'
                aria-label='account of current user'
                aria-controls='menu-appbar'
                aria-haspopup='true'
                onClick={handleOpenNavMenu}
                color='inherit'
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id='menu-appbar'
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.route}
                    onClick={() => handleCloseNavMenu(page.route)}
                  >
                    <Link to={page.route} style={{ color: 'white' }}>
                      {page.title}
                    </Link>
                  </MenuItem>
                ))}
                {/* <MenuItem sx={{ fontWeight: 700 }}>LINKS</MenuItem> */}
                <CustomLinks />
              </Menu>
            </Box>
          ) : null}
          <HandymanIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant='h5'
            noWrap
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <Link to='/' style={{ color: 'white' }}>
              ADVUTILS
            </Link>
          </Typography>
          {context.currentUser ? (
            <React.Fragment>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                {pages.map((page) => (
                  <MenuItem
                    key={page.route}
                    onClick={() => handleCloseNavMenu(page.route)}
                  >
                    <Link to={page.route} style={{ color: 'white' }}>
                      {page.title}
                    </Link>
                  </MenuItem>
                ))}
                <CustomLinks />
              </Box>
              <MenuItem onClick={() => signOut(auth).then(() => navigate('/'))}>
                LOGOUT
              </MenuItem>
            </React.Fragment>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  )
}
