import {
  Autocomplete,
  Box,
  Button,
  Input,
  Textarea,
  Typography,
} from '@mui/joy'
import MyCard from '../../MyCard'
import { QualificacaoType } from './AutoDocsIndex'
import {
  AlignmentType,
  BorderStyle,
  Document,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  convertMillimetersToTwip,
} from 'docx'
import {
  createFooter,
  createHeader,
  createBoldText,
  whereAndWhen,
  createNormalText,
  jumpLine,
  saveDoc,
  getNameInitials,
} from '../../../utils/docsHelper'
import { useState } from 'react'

interface IContratoProps {
  qualificacao: QualificacaoType
  finalidade: string
  setFinalidade: React.Dispatch<React.SetStateAction<string>>
}

interface IOAB {
  [keys: string]: string
}

export default function Contrato({
  qualificacao,
  finalidade,
  setFinalidade,
}: IContratoProps) {
  // https://mui.com/joy-ui/react-autocomplete/#controlled-states
  const [administrador, setAdministrador] = useState<string | null>('')
  const oab: IOAB = {
    'MAYCON B. LOPES': '215.717',
    'LUIZ OTÁVIO DE A. CASTRO': '166.909',
    'ANA LUISA V. RAMOS': '199.295',
  }

  const [inputValue, setInputValue] = useState<string>('')

  const [honorarios, setHonorarios] = useState<number>(30)
  const [consulta, setConsulta] = useState<number>(0)
  const [dataPagamentoConsulta, setDataPagamentoConsulta] = useState<string>('')

  function handleCreateDoc() {
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                bottom: `${4}cm`,
              },
            },
          },
          headers: createHeader(),
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                createBoldText('CONTRATO DE HONORÁRIOS ADVOCATÍCIOS', 16),
              ],
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                createBoldText('CONTRATANTE'),
                createBoldText(` ${qualificacao.nome}`),
                createNormalText(
                  `, ${qualificacao.nacionalidade}, ${qualificacao.estadoCivil}, ${qualificacao.profissao}, nascido(a) em ${qualificacao.nascimento}, RG nº ${qualificacao.rg}, CPF nº ${qualificacao.cpf}, residente e domiciliado(a) à ${qualificacao.endereco}`
                ),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                createBoldText('CONTRATADOS: '),
                createBoldText(' MAYCON BARRETO LOPES'),
                createNormalText(
                  ', brasileiro, casado, advogado, inscrito na OAB/RJ sob o nº 215.717;'
                ),
                createBoldText(' LUIZ OTÁVIO DE ARAÚJO CASTRO'),
                createNormalText(
                  ', brasileiro, casado, advogado, inscrito na OAB/RJ sob nº 166.909;'
                ),
                createBoldText(' ANA LUISA VASCONCELLOS RAMOS'),
                createNormalText(
                  ', brasileira, solteira, advogada, inscrita na OAB/RJ sob o nº 199.295;'
                ),
                createNormalText(
                  ' estabelecidos na Rua Saldanha Marinho, 450/464 - Sala 709, Edifício Connect Work Station, Centro – Campos dos Goytacazes/RJ – CEP: 28.010-272.'
                ),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'As partes acima identificadas têm, entre si, justo e acertado o presente Contrato de Honorários Advocatícios, que se regerá pelas cláusulas seguintes e pelas condições descritas no presente.',
                  bold: true,
                  italics: true,
                  size: `${12}pt`,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DO OBJETO',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLAUSULA 1ª: '),
                createNormalText(
                  'Propor ação indenizatória em razão de danos causados por cobrança indevida de IPTU.'
                ),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DO ADMINISTRADOR',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLAUSULA 2ª: '),
                createNormalText(
                  'A administração do contrato será realizada pelo advogado'
                ),
                createBoldText(` ${administrador}`),
                createNormalText(', acima já qualificado(a).'),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DOS HONORÁRIOS',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLÁUSULA 3ª:'),
                createNormalText(
                  ` Pelos serviços prestados na Cláusula Primeira, o CONTRATANTE, pagará aos CONTRATADOS o correspondente a ${honorarios}% do aproveitamento econômico obtido somente ao final da demanda.`
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DOS PAGAMENTOS',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLÁUSULA 4ª:'),
                createNormalText(
                  ' O pagamento dos valores referentes ao contrato entabulado poderá ser efetuado em espécie, por meio de boleto bancário, cartão de crédito/débito ou ainda por meio de depósito/transferência em conta corrente oportunamente informada.'
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText(
                  consulta ? 'Parágrafo Primeiro:' : 'Parágrafo Único:'
                ),
                createNormalText(
                  consulta
                    ? ` A título de assessoria e consulta jurídica, bem como para custear as diligências iniciais, o CONTRATANTE pagará aos CONTRATADOS o valor de R$ ${consulta} até o dia ${dataPagamentoConsulta.replace(/(\d{4})-(\d{2})-(\d{2})/gm, '$3/$2/$1')}.`
                    : ' Em caso de inadimplemento de quaisquer valores, haverá incidência de mora no importe de 10% (dez por cento) sobre o valor devido, acrescidos de juros de 2% ao mês e correção monetária.'
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText(consulta ? 'Parágrafo Segundo:' : ''),
                createNormalText(consulta 
                  ? '  Em caso de inadimplemento de quaisquer valores, haverá incidência de mora no importe de 10% (dez por cento) sobre o valor devido, acrescidos de juros de 2% ao mês e correção monetária.'
                  : ''
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DO SIGILO E CONFIDENCIALIDADE',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLÁUSULA 5ª:'),
                createNormalText(
                  ' O presente contrato é regido por obrigação recíproca de confidencialidade e sigilo, de modo que todos os dados e informações, verbais, escritas e visuais, estejam protegidos, inclusive por direitos autorais, sob pena de multa no valor de 30% (trinta por cento) do valor da ação, reservando-se o direito a fornecer nos autos dos processos informações necessárias ao prosseguimento da presente.'
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('Parágrafo único:'),
                createNormalText(' As partes poderão livremente, recomendar e divulgar para terceiros interessados o objeto da presente ação.')
              ]
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DA RESCISÃO',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLÁUSULA 6ª:'),
                createNormalText(
                  ' O presente contrato poderá ser rescindido por qualquer das partes, mediante aviso prévio, por escrito, com 30 (trinta) dias de antecedência, incidindo nesse caso a proporcionalidade dos honorários prestados, os quais considerar-se-ão vencidos.'
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DO SIGILO E CONFIDENCIALIDADE',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLÁUSULA 5ª:'),
                createNormalText(
                  ' O presente contrato é regido por obrigação recíproca de confidencialidade e sigilo, de modo que todos os dados e informações, verbais, escritas e visuais, estejam protegidos, inclusive por direitos autorais, sob pena de multa no valor de 30% (trinta por cento) do valor da ação, reservando-se o direito a fornecer nos autos dos processos informações necessárias ao prosseguimento da presente.'
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('Parágrafo único:'),
                createNormalText(' As partes poderão livremente, recomendar e divulgar para terceiros interessados o objeto da presente ação.')
              ]
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'DO FORO',
                  bold: true,
                  underline: {},
                }),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createBoldText('CLÁUSULA 7ª:'),
                createNormalText(
                  ' As partes, de comum acordo, elegem o foro da Comarca de Campos dos Goytacazes/RJ como o único competente para dirimir quaisquer dúvidas ou litígios decorrentes do presente Contrato.'
                ),
              ],
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                createNormalText('E por estarem certas e avençadas, as partes assinam o presente Contrato, em duas vias de igual teor e forma.')
              ]
            }),
            jumpLine(),
            new Paragraph({
              alignment: AlignmentType.END,
              children: [
                new TextRun({
                  text: whereAndWhen('Campos dos Goytacazes/RJ', new Date()),
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Table({
              columnWidths: [convertMillimetersToTwip(80), convertMillimetersToTwip(80)],
              rows: [
                new TableRow({
                  children: [
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE
                        },
                        bottom: {
                          style: BorderStyle.NONE
                        },
                        right: {
                          style: BorderStyle.NONE
                        },
                        left: {
                          style: BorderStyle.NONE
                        },

                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: '__________________________________',
                              size: `${12}pt`,
                            }),
                          ],
                        }),
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            createBoldText(qualificacao.nome),
                          ],
                        }),
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            createBoldText(`CPF ${qualificacao.cpf}`)
                          ]
                        })
                      ]
                    }),
                    new TableCell({
                      borders: {
                        top: {
                          style: BorderStyle.NONE
                        },
                        bottom: {
                          style: BorderStyle.NONE
                        },
                        right: {
                          style: BorderStyle.NONE
                        },
                        left: {
                          style: BorderStyle.NONE
                        },

                      },
                      children: [
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text: '__________________________________',
                              size: `${12}pt`,
                            }),
                          ],
                        }),
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            createBoldText(administrador ? administrador : '')
                          ],
                        }),
                        new Paragraph({
                          alignment: AlignmentType.CENTER,
                          children: [
                            createBoldText(administrador ? 'OAB/RJ ' + oab[administrador] : '')
                          ]
                        })
                      ]
                    })
                  ]
                })
              ]
            }),
            
          ],
          footers: createFooter(),
        },
      ],
    })
    saveDoc(doc, `Contrato-Honorarios_${getNameInitials(qualificacao.nome)}`)
  }

  function handleCleanUp(){
    setFinalidade('')
    setAdministrador('')
    setHonorarios(0)
    setConsulta(0)
    setDataPagamentoConsulta('')
  }

  return (
    <MyCard>
      <Box display='flex' flexDirection='column' gap='10px'>
        <Typography level='h3' sx={{ color: 'white' }}>
          NOVO CONTRATO
        </Typography>
        <Textarea
          minRows={2}
          placeholder='OBJETO (= finalidade)'
          value={finalidade}
          onChange={(e) => {
            let value = e.target.value
            value = value.replace(/\.$/, '')
            setFinalidade(value)
          }}
        />
        <Autocomplete
          placeholder='ADMINISTRADOR'
          size='lg'
          options={[
            '',
            'ANA LUISA V. RAMOS',
            'LUIZ OTÁVIO DE A. CASTRO',
            'MAYCON B. LOPES',
          ]}
          value={administrador}
          onChange={(e, newValue) => setAdministrador(newValue)}
          inputValue={inputValue}
          onInputChange={(e, newInputValue) => setInputValue(newInputValue)}
        />
        <Input
          startDecorator='HONORÁRIOS'
          endDecorator='%'
          value={honorarios}
          onChange={(e) => setHonorarios(Number(e.target.value))}
          sx={{ width: '200px' }}
        />
        <Input
          startDecorator='CONSULTA? R$'
          value={consulta}
          onChange={(e) => setConsulta(Number(e.target.value))}
          sx={{ width: '200px' }}
        />
        {consulta ? (
          <Input 
            type='date'
            value={dataPagamentoConsulta}
            onChange={(e) => setDataPagamentoConsulta(e.target.value)}
          />

        ) : null
        }
        <Box display='flex' justifyContent='space-evenly'>
          <Button onClick={handleCreateDoc}>GERAR CONTRATO</Button>
          <Button onClick={handleCleanUp}>LIMPAR</Button>
        </Box>
      </Box>
    </MyCard>
  )
}
