import { useState } from 'react'
import Qualificacao from './pages/auto-docs/Qualificacao'
import { QualificacaoType } from './pages/auto-docs/AutoDocsIndex'

export default function NewCliente() {
  const qualificacaoInitialState = {
    nome: '',
    nacionalidade: '',
    estadoCivil: '',
    profissao: '',
    nascimento: '',
    rg: '',
    cpf: '',
    endereco: '',
  }

  const [qualificacao, setQualificacao] = useState<QualificacaoType>(
    qualificacaoInitialState
  )

  function resetQualificacao() {
    setQualificacao(qualificacaoInitialState)
  }

  return (
    <Qualificacao
      qualificacao={qualificacao}
      setQualificacao={setQualificacao}
      resetQualificacao={resetQualificacao}
    />
  )
}
