import { Box } from '@mui/material'

interface IMyBoxWithShadowProps {
    children: React.ReactNode
    width?: string
}

export default function MyBoxWithShadow({ children, width }: IMyBoxWithShadowProps) {
  return (
    <Box
      display='flex'
      flexDirection='column'
      gap='10px'
      alignItems='center'
      width={width}
      sx={{
        backgroundColor: '#2d2e33',
        padding: '20px',
        boxShadow: '2px 2px 5px black',
        borderRadius: '20px',
      }}
    >
      {children}
    </Box>
  )
}
