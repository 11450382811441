import { Box, Button, Textarea, Typography } from '@mui/joy'
import {
  AlignmentType,
  Document,
  Paragraph,
  TextRun,
} from 'docx'
import { QualificacaoType } from './AutoDocsIndex'
import MyCard from '../../MyCard'
import { createFooter, createHeader, getNameInitials, saveDoc, whereAndWhen } from '../../../utils/docsHelper'

interface IProcuracaoProps {
  qualificacao: QualificacaoType
  finalidade: string
  setFinalidade: React.Dispatch<React.SetStateAction<string>>
}

export default function Procuracao({ qualificacao, finalidade, setFinalidade }: IProcuracaoProps): JSX.Element {

  function handleCreateDoc(): void {
    const doc = new Document({
      sections: [
        {
          properties: {
            page: {
              margin: {
                bottom: `${4}cm`,
              },
            },
          },
          headers: createHeader(),
          children: [
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: 'PROCURAÇÃO',
                  bold: true,
                  size: `${16}pt`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'OUTORGANTE:',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ` ${qualificacao.nome}`,
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: `, ${qualificacao.nacionalidade}, ${qualificacao.estadoCivil}, ${qualificacao.profissao}, nascido(a) em ${qualificacao.nascimento}, RG nº ${qualificacao.rg}, CPF nº ${qualificacao.cpf}, residente e domiciliado(a) à ${qualificacao.endereco}.`,
                  size: `${12}pt`,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'OUTORGADOS:',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ' MAYCON BARRETO LOPES',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ', brasileiro, casado, advogado, inscrito na OAB/RJ sob o nº 215.717;',
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ' LUIZ OTÁVIO DE ARAÚJO CASTRO',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ', brasileiro, casado, advogado, inscrito na OAB/RJ sob nº166.909;',
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ' ANA LUIZA VASCONCELLOS RAMOS',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ', brasileira, solteira, advogada, inscrita na OAB/RJ sob o nº 199.295;',
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ' estabelecidos na Rua Saldanha Marinho, 450/464 - Sala 709, Edifício Connect Work Station, Centro – Campos dos Goytacazes/RJ – CEP: 28.010-272.',
                  size: `${12}pt`,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({
              children: [
                new TextRun({
                  text: 'FINALIDADE:',
                  bold: true,
                  size: `${12}pt`,
                }),
                new TextRun({
                  text: ` ${finalidade}.`,
                  size: `${12}pt`,
                }),
              ],
              alignment: AlignmentType.JUSTIFIED,
            }),
            new Paragraph({}),
            new Paragraph({
              alignment: AlignmentType.JUSTIFIED,
              children: [
                new TextRun({
                  text: 'Pelo presente instrumento particular de procuração e na melhor forma de direito, o OUTORGANTE acima qualificado, nomeia e constitui seus bastantes procuradores os OUTORGADOS, também acima qualificados, ao qual confere os poderes da cláusula “ad judicia et extra” para representarem o OUTORGANTE com finalidade supramencionada, em quaisquer feitos ou processos judiciais, ou administrativos de acordo, em que seja autor, réu, assistente, oponente, representante, representado ou por qualquer forma interessado, podendo, para tanto, agir em qualquer Juízo ou Tribunal, propor e variar de ações, apresentar peças de defesa, reconvenções, recursos, embargos, primeiras e últimas declarações, apelar, arrazoar, elaborar e impugnar cálculos, concordar, transigir, discordar, declarar hipossuficiência econômica, propor acordos, assinar recibos, receber e dar quitação, levantar alvarás judiciais, requerer a expedição dos mesmos em seu próprio nome junto à rede bancária oficial ou particular, requerer cadastros e certidões em bancos de dados protetivos de crédito, renunciar, desistir, substabelecer com ou sem reservas de iguais poderes, representar o outorgante, impetrar mandados de segurança e requerer correição parcial no processo.',
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              alignment: AlignmentType.END,
              children: [
                new TextRun({
                  text: whereAndWhen('Campos dos Goytacazes/RJ', new Date()),
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({}),
            new Paragraph({}),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: '____________________________________',
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `${qualificacao.nome}`,
                  bold: true,
                  size: `${12}pt`,
                }),
              ],
            }),
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  text: `CPF ${qualificacao.cpf}`,
                  bold: true,
                  size: `${12}pt`,
                }),
              ],
            }),
          ],
          footers: createFooter()
        },
      ],
    })
    saveDoc(doc, `Procuracao_${getNameInitials(qualificacao.nome)}.docx`)
  }
  return (
    <MyCard>
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography level="h3" sx={{ color: 'white' }}>
          NOVA PROCURAÇÃO
        </Typography>
        <Textarea minRows={2} placeholder='FINALIDADE' value={finalidade} onChange={(e) => {
          let value = e.target.value
          value = value.replace(/\.$/, '')
          setFinalidade(value)
        }} />
        <Button onClick={handleCreateDoc}>GERAR PROCURAÇÃO</Button>
      </Box>
    </MyCard>
  )
}
