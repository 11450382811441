import { Fragment, useState } from 'react'
import styles from './fixCarriage.module.css'

export default function FixCarriage(): JSX.Element {
  const [textToBeFixed, setTextToBeFixed] = useState('')
  const [textFixed, setTextFixed] = useState('')

  function carriageReturnFix() {
    //this function fixes carriage return characters when copying text from PDF files, for example
    // const regex: RegExp = /([\s\w,-])\n+/g
    const regex = /\r\n|\r|\n/g
    const match: RegExpExecArray | null = regex.exec(textToBeFixed)
    if (match) {
      setTextFixed(textToBeFixed.replace(regex, ' '))
    } else {
      setTextFixed('Ocorreu um erro no texto de entrada')
    }
  }

  return (
    <div className={styles.fixCarriageContainer}>
      <textarea
        className="text-area"
        value={textToBeFixed}
        placeholder="Insira aqui o texto que precisa ser corrigido"
        onChange={(e) => setTextToBeFixed(e.target.value)}
      />

      <button onClick={carriageReturnFix}>Corrigir quebras de linha</button>

      <textarea
        className="text-area"
        value={textFixed}
        readOnly={true}
      ></textarea>
    </div>
  )
}
