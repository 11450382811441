import Input, { InputPropsColorOverrides } from '@mui/joy/Input'
import Textarea from '@mui/joy/Textarea'
import Button from '@mui/joy/Button'
import Box from '@mui/joy/Box'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { useEffect, useState } from 'react'
import { ColorPaletteProp, IconButton } from '@mui/joy'
import { OverridableStringUnion } from '@mui/types'
import { Alert, Collapse, Typography } from '@mui/material'
import MyCard from '../../MyCard'

export default function FindAddressByCep() {
  const [cep, setCep] = useState<string>('')
  const [address, setAddress] = useState<string>('')
  const [cepInputColor, setCepInputColor] =
    useState<
      OverridableStringUnion<ColorPaletteProp, InputPropsColorOverrides>
    >('primary')

  const [searchButtonDisabled, setSearchButtonDisabled] =
    useState<boolean>(true)

  const [alertOpen, setAlertOpen] = useState(false)

  //valid CEP: 99999-999
  const regex = /\d{5}-\d{3}/

  useEffect(() => {
    if (cep) {
      if (regex.test(cep)) {
        setCepInputColor('primary')
        setSearchButtonDisabled(false)
      } else {
        setCepInputColor('warning')
        setSearchButtonDisabled(true)
      }
    }
  }, [cep])

  function handleOnChange(e: React.ChangeEvent<HTMLInputElement>) {
    e.target.maxLength = 9
    let value = e.target.value
    value = value.replace(/\D/g, '')
    value = value.replace(/^(\d{5})(\d{3})/, '$1-$2')

    setCep(value)
  }

  async function findCEP() {
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`)
      const result = await response.json()
      if (!result.erro) {
        const formatedResult = `${result.logradouro}, ${result.bairro}, ${result.localidade}/${result.uf}`
        setAddress(formatedResult)
      } else {
        setAddress('Não localizado, tente outro CEP')
      }
    } catch (error) {
      console.log('------', error)
    }
  }

  function handleCopyClick() {
    navigator.clipboard.writeText(address)
    setAlertOpen(true)
    setTimeout(() => {
      setAlertOpen(false)
    }, 2000)
  }

  function handleCleanClick() {
    setSearchButtonDisabled(true)
    setAddress('')
    setCep('')
  }

  return (
    <MyCard>
      <Collapse in={alertOpen}>
        <Alert sx={{ marginBottom: '10px' }}>{'Copiado!'}</Alert>
      </Collapse>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          justifyContent: 'space-between',
          marginTop: '12px',
        }}
      >
        <Typography variant='h4' sx={{ color: 'white' }}>
          Endereço pelo CEP
        </Typography>
        <Input
          placeholder='99999-999'
          color={cepInputColor}
          variant='plain'
          value={cep}
          onChange={handleOnChange}
        ></Input>
        <Box sx={{ display: 'flex', gap: '8px' }}>
          <Button
            startDecorator='🔎'
            onClick={findCEP}
            disabled={searchButtonDisabled}
          >
            Buscar
          </Button>
          <Button
            onClick={handleCleanClick}
            disabled={cep || address ? false : true}
          >
            Limpar
          </Button>
        </Box>
        <Textarea
          placeholder='Endereço'
          readOnly={true}
          minRows={3}
          value={address}
          sx={{ background: 'lightgrey' }}
          startDecorator={
            <IconButton
              onClick={handleCopyClick}
              disabled={address ? false : true}
            >
              <ContentCopyIcon>copiar</ContentCopyIcon>
            </IconButton>
          }
        ></Textarea>
      </Box>
    </MyCard>
  )
}
