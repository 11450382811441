// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from 'firebase/auth'

import { Firestore, collection, doc, getFirestore, writeBatch } from 'firebase/firestore'
import { ICliente } from '../../components/ClienteCard'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCqyDBCsRdRGom2x2gfFcFyOOrU0b4NpIs',
  authDomain: 'advutils-d6155.firebaseapp.com',
  projectId: 'advutils-d6155',
  storageBucket: 'advutils-d6155.appspot.com',
  messagingSenderId: '634701965190',
  appId: '1:634701965190:web:bb15a9efca7345b453ae07',
}

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig)

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(firebaseApp)

// Initialize Cloud Firestore and get a reference to the service
export const db: Firestore = getFirestore(firebaseApp)

export async function addCollectionAndDocuments(collectionKey: string, document: string, documentsToAdd: ICliente[]){
  const collectionRef = collection(db, collectionKey)
  const batch = writeBatch(db)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const items: any = []
  const docRef = doc(collectionRef, document)

  documentsToAdd.forEach((documentToAdd) => {
    items.push(documentToAdd)
  })
  
  batch.set(docRef, { items: items })
  await batch.commit()
  console.log('done batch')
}

